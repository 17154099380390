<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">出借人</span>
              <a-button block style="text-align: left">
                {{ user.userName }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">出借部门</span>
              <a-button block style="text-align: left">
                {{
                  department.deptUniqueName ? department.deptUniqueName : "--"
                }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="预期归还日期">
              <a-date-picker
                placeholder=""
                v-decorator="[
                  'expectReturnDate',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="最高保密级别">
              <a-button disabled block style="text-align: left">
                <DataDictFinder dictType="secretLevel" :dictValue="topLevel" />
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              label="出借事由"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remarks',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="title">借出档案清单</div>

        <a-table bordered :dataSource="list" :pagination="false">
          <a-table-column title="档案编号" data-index="code"> </a-table-column>

          <a-table-column title="档案名称" data-index="name" />
          <a-table-column title="类型">
            <template slot-scope="text">
              {{ getTypeText(text.type) }}
            </template>
          </a-table-column>
          <a-table-column title="保密级别" align="center" width="100px">
            <template slot-scope="text">
              <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center" width="100px">
            <template slot-scope="text, record, index">
              <a href="#" @click.prevent="remove(index)"> 移除 </a>
            </template>
          </a-table-column>
        </a-table>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">
              {{ id ? "重新提交" : "保存" }}
            </a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { flatList } from "@/utils/flat";
import { borrow, fetchBorrowDetail, reBorrow } from "@/api/archives";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  mixins: [watermark],

  data() {
    return {
      form: this.$form.createForm(this),
      list: [],
      loading: false,
      id: null, // 已发起出借申请的 id
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id) {
      // 重新提交
      this.id = id;
      fetchBorrowDetail({
        id,
      }).then((res) => {
        console.log("result", res);
        if (res) {
          this.form.setFieldsValue({
            expectReturnDate: res.expectReturnDate
              ? moment(res.expectReturnDate)
              : undefined,
            remarks: res.remarks,
          });

          this.list = Array.isArray(res.borrowArchiveList)
            ? res.borrowArchiveList
            : [];
        }
      });
    } else {
      const rawStr = window.localStorage.getItem("lend-archives");
      if (rawStr) {
        const list = JSON.parse(rawStr);
        console.log("list", list);
        if (Array.isArray(list)) {
          this.list = list;
        }
      }
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),

    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("arcType");
    },

    flatTypeList() {
      let result = [];
      flatList(this.typeList, result);
      return result;
    },

    topLevel() {
      const levels = this.list.map((item) => item.level);
      if (levels.indexOf("topsecret") > -1) {
        return "topsecret";
      } else if (levels.indexOf("confidential") > -1) {
        return "confidential";
      } else if (levels.indexOf("secret") > -1) {
        return "secret";
      } else {
        return "";
      }
    },
  },

  methods: {
    getTypeText(type) {
      if (typeof type === "string") {
        const arr = type.split(",");
        return arr
          .map((item) => {
            const obj = this.flatTypeList.find(
              (element) => element.value === item
            );
            return obj ? obj.name : "";
          })
          .join(" - ");
      } else {
        return "";
      }
    },

    remove(index) {
      this.list.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.list.length === 0) {
            this.$message.error("请选择出借档案");
            return;
          }

          this.loading = true;
          const { remarks } = values;

          let expectReturnDate = values.expectReturnDate.format("YYYY-MM-DD");

          if (this.id) {
            reBorrow({
              id: this.id ? this.id : undefined,

              // 出借人
              borrowerId: this.user.uuid,
              borrowerName: this.user.userName,
              // 出借部门
              deptId: this.department.deptId,
              deptName: this.department.deptUniqueName,
              expectReturnDate,
              remarks,
              level: this.topLevel,
              borrowArchiveList: this.list.map((item) => {
                return {
                  arcId: item.id,
                  expectReturnDate,
                };
              }),
            })
              .then(() => {
                this.$close(this.$route.path);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            borrow({
              // 出借人
              borrowerId: this.user.uuid,
              borrowerName: this.user.userName,
              // 出借部门
              deptId: this.department.deptId,
              deptName: this.department.deptUniqueName,

              expectReturnDate,
              remarks,
              level: this.topLevel,
              borrowArchiveList: this.list.map((item) => {
                return {
                  arcId: item.id,
                  expectReturnDate,
                };
              }),
            })
              .then(() => {
                this.list = [];
                this.form.resetFields();
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.title {
  margin-top: 30px;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: #1890ff;
}

.center {
  margin-top: 80px;
}
</style>
